'use client';

import { useClientSideSentry } from '@/hooks/useClientSideSentry/useClientSideSentry';
import NextError from 'next/error';
import React from 'react';
import { useEffect } from 'react';

// to handle errors within the root layout.
// See: https://nextjs.org/docs/app/building-your-application/routing/error-handling#handling-errors-in-root-layouts

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const Sentry = useClientSideSentry();

  useEffect(() => {
    Sentry.captureException(error);
  }, [Sentry, error]);

  return (
    // global-error must include html and body tags
    <html>
      <body>
        <NextError statusCode={500} />
      </body>
    </html>
  );
}
