import { ConsentState, useConsent, UserCentricsServiceId } from '@grimme/gdap';
//README: Find further documentation in the readme.md file in the root of the repository
import { ExclusiveEventHintOrCaptureContext } from '@sentry/core/types/utils/prepareEvent';
import * as Sentry from '@sentry/nextjs';

export const useClientSideSentry = () => {
  const isSentryConsentGiven = useConsent(UserCentricsServiceId.SENTRY);

  const captureException = (
    exception: any,
    hint?: ExclusiveEventHintOrCaptureContext,
  ) => {
    if (isSentryConsentGiven === ConsentState.REJECTED) {
      return 'not-sent';
    }

    return Sentry.captureException(exception, hint);
  };

  return {
    captureException,
  };
};
